
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import { rentMediaFilesModule } from '../../shared/state/state';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { statuses } from '@/shared/config/rents';

@Component({
    components: {
        NameReduce,
    },
})
export default class RentCard extends Vue {
    @Prop(Number) public id!: number;
    @Prop(String) public name!: string;
    @Prop(Boolean) public disabled!: boolean;
    @Prop(String) public address!: string;
    @Prop(String) public code!: string;
    @Prop(String) public status!: string;


    public mediaActionsTypes = rentMediaFilesModule.actionsTypes;
    public fetchMediaAction = this.mediaActionsTypes.FETCH_DATA;

    public tumbnailUrl: string = '';

    get itemStatus() {
        return statuses.find((status) => status.value === this.status);
    }

    public created() {
        this.$store.dispatch(this.fetchMediaAction, { id: this.id, simple: true }).then(({ data }) => {
            const mediaFile = data[0]?.media_file;

            if (!mediaFile || !mediaFile.has_thumbnail) { this.tumbnailUrl = '/assets/photo.png';
            } else { this.tumbnailUrl = mediaFile.thumbnail; }
        });
    }
}
