
    import { Component } from 'vue-property-decorator';
    import { rentersModule } from '../shared/state/module';
    import { rentersListHeaders } from '@/modules/renters/shared/config';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import ContractorDetails from '@/modules/renters/components/details.vue';
    import RenterForm from '@/modules/renters/components/form.vue';
    import { RenterDataRequest } from '@/modules/renters/shared/requests/renter-data-request';
    import { rentersListFilters } from '@/modules/renters/shared/config/filters';
    import { ModuleProps } from '@/shared/state/template/module-props';
    import { Renter } from '../shared/models/renter';
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { ListFilter } from '@/shared/types';

    import UserDialog from '../../admin/components/users/card.vue';

    @Component({
        components: {
            ItemsTable,
            ContractorDetails,
            RenterForm,
            UserDialog,
        },
    })
    export default class ContractorsList extends ListComponent<Renter, RenterDataRequest> {
        public headers: object[] = rentersListHeaders;
        public store = this.$store.state.rentersState;
        public actionsTypes = rentersModule.actionsTypes;
        public mutationTypes = rentersModule.mutationsTypes;
        public fetchAction = this.actionsTypes.FETCH_DATA;
        public removeAction = this.actionsTypes.REMOVE_ITEM;
        public props: ModuleProps = rentersModule.moduleProps;
        public filterConfig: ListFilter[] = rentersListFilters;

        public guardianDialog: any = null;

        public created() {
            this.fetchData();
        }

    }
