import { HeaderFieldType } from '@/shared/types';

export const rentersListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Pełna nazwa', value: 'name' },
    { text: 'Miejsce zamieszkania', value: 'home' },
    { text: 'Telefon', value: 'phone' },
    { text: 'E-mail', value: 'email' },
    { text: 'Status', value: 'type' },
    { text: 'Przedmiot', value: 'object' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const rentersTypesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const notesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];
