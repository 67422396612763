
    import { Component } from 'vue-property-decorator';
    import { mdiEmail, mdiPhone } from '@mdi/js';
    import DetailsComponent from '@/shared/components/layout/details/details-component';
    import TitleBar from '@/shared/components/layout/details/title-bar.vue';
    import { ModuleProps } from '@/shared/state/template/module-props';

    // module
    import { notesTypesModule } from '../../shared/state/notes/module';
    // request
    import { NoteDataRequest } from '../../shared/requests/note-data-dequest';

    @Component({
        props: {
            id: Number,
        },
        components: {
            TitleBar,
        },
    })
    export default class NoteDetails extends DetailsComponent<NoteDataRequest> {
        public icons: object = {
            mdiPhone,
            mdiEmail,
        };
        public props: ModuleProps = notesTypesModule.moduleProps;
        public mutationTypes = notesTypesModule.mutationsTypes;
        public actionsTypes = notesTypesModule.actionsTypes;
        public showAction = this.actionsTypes.SHOW_ITEM;
        public store = this.$store.state.rentersState.notesTypesModuleState;

        public created() {
            if (Number(this.id)) {
                this.fetchDetails();
            }
        }
    }
