
    import { Component, Prop } from 'vue-property-decorator';
    import DetailsComponent from '@/shared/components/layout/details/details-component';
    import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';

    import { rentersModule } from '@/modules/renters/shared/state/module';
    import { RenterDataRequest } from '@/modules/renters/shared/requests/renter-data-request';

    import ContractorForm from './form.vue';
    import NotesList from './notes/list.vue';

    import { actionsTypes } from '../../admin/shared/state/types';
    import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
    import RelatedRentDetails from './rent/details.vue';

    @Component({
        components: {
            TopToolbar,
            NotesList,
            ContractorForm,
            TooltipBtn,
            RelatedRentDetails,
        },
    })
    export default class ContactDetails extends DetailsComponent<RenterDataRequest> {
        @Prop(Number) public renterId!: number;
        public store = this.$store.state.rentersState;
        public actionsTypes = rentersModule.actionsTypes;
        public mutationTypes = rentersModule.mutationsTypes;
        public showAction = this.actionsTypes.SHOW_ITEM;
        public tab: any = null;
        public users: any = [];
        public guardianId: any = null;
        public guardianLoading: boolean = false;

        get editedItem() {
            return this.store.editedItem;
        }

        get formDialog() {
            return this.store.formDialog;
        }
        set formDialog(newVal) {
            this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, newVal);
        }

        public created() {
            this.fetchDetails();
            this.$store
                .dispatch(actionsTypes.FETCH_USER_DATA)
                .then((res) => {
                    this.users = this.pullToArraysHeadCurrentUser(res.data);
                });
        }
        public mounted() {
            const idNote = Number(this.$route.params.idNote);
            const currentTab = this.$route.query.currentTab;
            setTimeout(() => {
                if (idNote) {
                    this.tab = 1;
                }
                if (currentTab) {
                    if (currentTab === 'notes') {
                        this.tab = 1;
                    } else if (currentTab === 'objects') {
                        this.tab = 0;
                    }
                }
            }, 200);
        }
        public isPermitted(action: string) {
            return this.permissionCheck(`renters.${action}`);
        }
        get currentUser() {
            return this.$store.state.authState.user;
        }

        public pullToArraysHeadCurrentUser(arrayUsers: [any]) {
            const currentArray = [...arrayUsers];
            const { id, name } = this.currentUser;
            const currentUser = {
                ...this.currentUser,
                name: `(Ja) ${name}`,
            };
            const arrayWithoutCurrentUser = this._.filter(currentArray, this._.negate(this._.matches({ id })));
            return [currentUser, ...arrayWithoutCurrentUser];
        }

        public editItem() {
            this.$store.commit(this.mutationTypes.UPDATE_EDITED, this.item);
            this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, true);
        }

        public itemSaved() {
            this.formDialog = false;
            this.fetchDetails();
        }

    }
