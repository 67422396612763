import { render, staticRenderFns } from "./renters.vue?vue&type=template&id=bc9981a8&scoped=true&"
import script from "./renters.vue?vue&type=script&lang=ts&"
export * from "./renters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc9981a8",
  null
  
)

export default component.exports