
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module
import {rentersTypesModule} from '../../../shared/state/settings/renter-types/module';

// dataRequest
import {RenterTypeDataRequest} from '../../../shared/requests/settings/renters-type-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions},
})
export default class ObjectsTypesSettingsForm extends FormComponent<RenterTypeDataRequest> {
    public actionsTypes = rentersTypesModule.actionsTypes;
    public store = this.$store.state.rentersState.rentersTypesModuleState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public mutationTypes = rentersTypesModule.mutationsTypes;
    public requestData = new RenterTypeDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj status najemcy' : 'Dodaj status najemcy';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }
}
