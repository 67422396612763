
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { Component } from 'vue-property-decorator';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { ModuleProps } from '@/shared/state/template/module-props';

    // state module
    import { rentersTypesModule } from '../../../shared/state/settings/renter-types/module';

    // dataResponse
    import { RenterType } from '../../../shared/models/settings/renters-type';

    // dataRequest
    import { RenterTypeDataRequest } from '../../../shared/requests/settings/renters-type-data-request';

    // headers
    import { rentersTypesListHeaders } from '../../../shared/config/data-settings';

    // form
    import RenterTypeForm from './form.vue';

    @Component({
        components: {
            ItemsTable,
            RenterTypeForm,
        },
    })
    export default class ObjectsTypesSetting extends ListComponent<
        RenterType,
        RenterTypeDataRequest
    > {
        public headers: object[] = rentersTypesListHeaders;
        public actionsTypes = rentersTypesModule.actionsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public mutationTypes = rentersTypesModule.mutationsTypes;
        public store: any = this.$store.state.rentersState.rentersTypesModuleState;
        public props: ModuleProps = rentersTypesModule.moduleProps;

    }
