
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RentDataRequest } from '@/modules/rents/shared/requests/rent-data-request';
import { rentsModule } from '@/modules/rents/shared/state/state';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import RentCard from '@/modules/rents/components/details/rent-card.vue';
import { reservationDurations } from '@/shared/config/rents';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { rentersModule } from '../../shared/state/module';
import { relatedRentsModule } from '../../shared/state/related-rents/module';
import { httpClient } from '@/shared/services';

@Component({
    components: {
        FormActions,
        CardPicker,
        RentCard,
        DatePicker,
    }
})
export default class RelatedRentsForm extends FormComponent<RentDataRequest> {
    public rentersStore = this.$store.state.rentersState;
    public rentsAction = rentsModule.actionsTypes;
    public relatedAction = relatedRentsModule.actionsTypes;
    public rentsFetchAction = this.rentsAction.FETCH_DATA;
    public rentsStore = this.$store.state.rentersState.relatedRentsState;
    public reservationDurations = reservationDurations;
    public reservationDuration = 0;
    public item: any = {};
    public isLoading = false;

    get formTitle() {
        return !this.edit ? 'Utwórz powiązanie' : 'Edytuj powiązanie';
    }

    public created() {
        if (this.$store.state.rentersState.relatedRentsState.data &&
            this.$store.state.rentersState.relatedRentsState.data[0]) {
            const data = this.rentersStore.relatedRentsState.data[0];
            this.rentersStore.relatedRentsState.current = data;
            this.item = this.rentersStore.relatedRentsState.current;
        } else {
            this.rentersStore.relatedRentsState.current = null;
        }
    }

    public onReservationStartDateChange(value: string) {
        if (this.reservationDuration === 0) {
            this.item.start_date = this.parseDate(value);
        } else
        if (this.edit) {
            this.item.start_date = this.parseDate(value);
            if (this.reservationDuration !== 0) {
                const reservationEndDate = new Date(value);
                reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);
                this.item.end_date = this.parseDate(reservationEndDate);
            }
        } else
        if (!this.edit) {
            const reservationEndDate = new Date(value);
            reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);

            this.item.start_date = this.parseDate(value);

            this.item.end_date = this.parseDate(reservationEndDate);
        }
    }

    public onReservationEndDateChange(value: string) {
        this.item.end_date = this.parseDate(value);
    }

    public onReservationDurationChange() {
        const reservationEndDate = new Date(this.item.start_date);
        reservationEndDate.setDate( reservationEndDate.getDate() + this.reservationDuration);
        this.item.end_date = this.parseDate(reservationEndDate);
    }

    public submitRelated() {
        this.isLoading = true;
        const item = {
            rent_id: this.rentersStore.relatedRentsState.current.id,
            renter_id: this.rentersStore.current.id,
            start_date: this.item.start_date,
            end_date: this.item.end_date,
        };
        this.$store.dispatch(this.relatedAction.STORE_ITEM, item)
            .then((data: any) => {
                this.$emit('updated');
                this.isLoading = false;
            })
            .catch((error: any) => {
                this.isLoading = false;
            });
    }

    public isTaken(item: any) {
        if (item.renters.length > 0) {
            if (this.edit && item.id === this.rentersStore.relatedRentsState.data[0].id) {
                return false;
            }
            return true;
        }
        return false;
    }

}



