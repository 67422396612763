
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { Component } from 'vue-property-decorator';
import { RenterDataRequest } from '../../shared/requests/renter-data-request';
import RelatedRentsForm from './form.vue';
import { httpClient } from '@/shared/services';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { statuses } from '@/shared/config/rents';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';


@Component({
    components: {
        RelatedRentsForm,
        TooltipBtn,
        ActionConfirmDialog,
    }
})
export default class RelatedRentDetails extends DetailsComponent<RenterDataRequest> {
    public store = this.$store.state.rentersState.relatedRentsState;
    public relatedRentsDialog = false;
    public removeDialog = false;

    get isLoading() {
        return this.$store.state.rentersState.relatedRentsState.loading;
    }

    set isLoading(val: boolean) {
        this.$store.state.rentersState.relatedRentsState.loading = val;
    }

    get relatedRent() {
        return this.store.data ? this.store.data[0] : null;
    }

    get itemStatus() {
        return statuses.find((status) => status.value === this.item.status);
    }

    get itemStatuses() {
        return statuses;
    }

    get removeLoading() {
        return this.$store.state.rentersState.relatedRentsState.removing;
    }

    set removeLoading(val: boolean) {
        this.$store.state.rentersState.relatedRentsState.removing = val;
    }

    public created() {
        this.fetchData();
    }

    public fetchData() {
        this.isLoading = true;
        httpClient.get(`/api/v1/renters/${this.id}/rents`)
            .then((data: any) => {
                this.store.data = data.data.data;
                this.isLoading = false;
            })
            .catch((error: any) => {
                this.isLoading = false;
            });
    }

    public openFormDialog() {
        this.relatedRentsDialog = true;
    }

    public onUpdate() {
        this.relatedRentsDialog = false;
        this.fetchData();
    }

    public removeRelatedRent() {
        this.removeLoading = true;
        httpClient.delete(`/api/v1/renters/${this.id}/rents/${this.relatedRent.id}`)
        .then(() => {
            this.removeLoading = false;
            this.removeDialog = false;
            this.fetchData();
        })
        .catch((error: any) => {
            this.removeLoading = false;
            this.removeDialog = false;
        });
    }
}

