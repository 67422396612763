import {mdiBarcode, mdiNumeric, mdiStairs} from '@mdi/js';
import {VariableFieldType} from '@/shared/types';

export const reservationDurations = [
    {value: 30, name: 'Miesiąc'},
    {value: 90, name: '3 miesiące'},
    {value: 0, name: 'Niestandardowy'}
];

export const rentsMediaAvailable = [
    'Woda',
    'Gaz',
    'Prąd',
    'Internet',
    'Telewizja',
    'Telefon',
    'Ogrzewanie',
];


export const statuses = [
    {value: 'free', name: 'Wolny'},
    {value: 'rented', name: 'Wynajęty'},
    {value: 'renovation', name: 'W remoncie'},
];

export const rentTypes = [
    {id: 'home', name: 'Mieszkanie'},
    {id: 'building', name: 'Dom'},
];

export const rentPurposes = [
    {id: 'live', name: 'Mieszkalny'},
    {id: 'service', name: 'Usługowy'},
];

export const propertyActions = [
    {action: 'replace', label: 'Dodaj (Nadpisz)'},
    {action: 'add', label: 'Dodaj (Zostaw istniejące)'},
    {action: 'change-value', label: 'Zmień wartość'},
    {action: 'change-public', label: 'Zmień widoczność'},
    {action: 'remove', label: 'Usuń'},
];

export const mediaActions = [
    {action: 'replace', label: 'Zastąp istniejące'},
    {action: 'remove', label: 'Usuń zaznaczone'},
    {action: 'add', label: 'Dodaj do istniejących'},
];

export const levelVariables: VariableFieldType[] = [
    {
        icon: mdiStairs,
        text: 'Numer poziomu',
        value: 'numer_poziomu',
    },
];

export const rentNameVariables: VariableFieldType[] = [
    {
        icon: mdiNumeric,
        text: 'Numer przedmiotu',
        value: 'numer_przedmiotu',
    },
];

export const rentCodeVariables: VariableFieldType[] = [
    {
        icon: mdiBarcode,
        text: 'Automatyczny',
        value: 'automatyczny',
        overwrite: true,
    },
];

export const knownFieldNames = {
    name: 'Nazwa',
    rent_type_id: 'Typ',
    description: 'Opis',
    code: 'Identyfikator',
    price: 'Cena',
    status: 'Status',
    properties: 'Właściwości',
    media: 'Media',
};


