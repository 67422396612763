
    import { Component, Watch } from 'vue-property-decorator';
    import { rentersModule } from '@/modules/renters/shared/state/module';
    import { RenterDataRequest } from '@/modules/renters/shared/requests/renter-data-request';
    import FormComponent from '@/shared/components/layout/form/form-component';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import ItemsSearch from '@/shared/components/layout/form/item-search.vue';
    import { mdiAccountSearch } from '@mdi/js';
    import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';

    import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
    import { rentersTypesModule } from '../shared/state/settings/renter-types/module';
    import RenterTypeForm from './settings/renter-types/form.vue';
    import { actionsTypes } from '@/modules/admin/shared/state';
    import { callSuper } from '@/shared/helpers';

    @Component({
        components: {
            FormActions,
            ItemsSearch,
            AutoCompleteWithAddNew,
            ActionConfirmDialog,
        },
    })
    export default class RenterForm extends FormComponent<RenterDataRequest> {
        public store = this.$store.state.rentersState;
        public actionsTypes = rentersModule.actionsTypes;
        public storeAction = this.actionsTypes.STORE_ITEM;
        public updateAction = this.actionsTypes.UPDATE_ITEM;
        public icons: object = {
            mdiAccountSearch,
        };
        public requestData = new RenterDataRequest();
        public searchDialog: boolean = false;

        // data required for add new type
        public rentersState = this.$store.state.rentersState.rentersTypesModuleState;
        public rentersTypesModule = rentersTypesModule;

        public RenterTypeForm = RenterTypeForm;

        // guardian
        public users: any = [];
        public guardianId: any = null;
        public guardianLoading: boolean = false;

        public created() {
            this.guardianLoading = true;
            this.$store
                .dispatch(actionsTypes.FETCH_USER_DATA)
                .then((res) => {
                    this.users = this.pullToArraysHeadCurrentUser(res.data);
                })
                .finally(() => {
                    this.guardianLoading = false;
                });
        }

        get currentUser() {
            return this.$store.state.authState.user;
        }

        public pullToArraysHeadCurrentUser(arrayUsers: [any]) {
            const currentArray = [...arrayUsers];
            const { id, name } = this.currentUser;
            const currentUser = {
                ...this.currentUser,
                name: `(Ja) ${name}`,
            };
            const arrayWithoutCurrentUser = this._.filter(currentArray, this._.negate(this._.matches({ id })));
            return [currentUser, ...arrayWithoutCurrentUser];
        }

        public itemSave(item: RenterDataRequest) {
            this.itemData = item;
            this.itemData.is_company = true;
            this.searchDialog = false;
        }

        get formTitle() {
            return this.edit ? 'Edytuj  najemcę' : 'Dodaj najemcę';
        }

        public async submitForm(e: any, next?: boolean): Promise<void> {

            return callSuper(this, 'submitForm', e, next);
        }

        public initForm() {
            this.form = this.$refs.createContractorForm;
        }
    }
