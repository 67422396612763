
import { Component, Prop } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ListFilter } from '@/shared/types';

    // module
import { notesTypesModule } from '../../shared/state/notes/module';
    // model
import { Note } from '../../shared/models/notes';
    // data request type
import { NoteDataRequest } from '@/modules/renters/shared/requests/note-data-dequest';
    // headers
import { notesListHeaders } from '@/modules/renters/shared/config';

    // components
import NoteForm from './form.vue';
import NoteDetails from './details.vue';
import { callSuper } from '@/shared/helpers';

@Component({
        components: {
            ItemsTable,
            NoteForm,
            NoteDetails,
        },
        props: {
            // contractor_id: Number,
        },
    })
    export default class NoteList extends ListComponent<Note, NoteDataRequest> {
        public headers: object[] = notesListHeaders;
        public store = this.$store.state.rentersState.notesTypesModuleState;
        public actionsTypes = notesTypesModule.actionsTypes;
        public mutationTypes = notesTypesModule.mutationsTypes;
        public fetchAction = this.actionsTypes.FETCH_DATA;
        public removeAction = this.actionsTypes.REMOVE_ITEM;
        public props: ModuleProps = notesTypesModule.moduleProps;
        public parent: any = {};

        public renterId: null | number = null;

        public showDialogFromUrl = false;
        public idDialogFromUrl: null | number = null;

        public fetchData() {
            this.fetchParams.filters = {
                ...this.fetchParams.filters,
                renter_id: this.renterId,
            };

            callSuper(this, 'fetchData' );
        }

        public created() {
            const params = this.$route.params;


            if (params.id) {
                this.renterId = Number(params.id);
            } else if (params.idRenter) {
                this.renterId = Number(params.idRenter);
            }

            this.fetchData();

            const idNote = Number(this.$route.params.idNote);
            if (idNote) {
                this.idDialogFromUrl = idNote;
                this.showDialogFromUrl = true;
            }
        }
    }
