
import { Component, Prop } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// module
import { notesTypesModule } from '../../shared/state/notes/module';
// data request type
import { NoteDataRequest } from '@/modules/renters/shared/requests/note-data-dequest';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';

import { rentersModule } from '../../shared/state/module';
import RenterForm from '../form.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { FormActions, AutoCompleteWithAddNew },
})
export default class ObjectsTypesSettingsForm extends FormComponent<NoteDataRequest> {
    public actionsTypes = notesTypesModule.actionsTypes;
    public mutationTypes = notesTypesModule.mutationsTypes;
    public store = this.$store.state.rentersState.notesTypesModuleState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new NoteDataRequest();

    public renterId: null | number = null;
    public contractorContactId: null | number = null;


    public rentersState = this.$store.state.rentersState;
    public rentersModule = rentersModule;
    public RenterForm = RenterForm;

    public created() {
        if (this.itemData.renter_id) {
            this.renterId = this.itemData.renter_id;
        }
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj notatkę' : 'Dodaj notatkę';
    }

    get contractorIdObject(): object {
        return { renter_id: this.renterId };
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        if (this.itemData.renter_id) {
            this.renterId = this.itemData.renter_id;
        }

        return callSuper(this, 'submitForm', e, next);
    }

    public changeRenterId(id: any) {
        this.renterId = id;
    }

    public initForm() {
        this.form = this.$refs.createContactForm;
    }

    public cancelForm() {
        callSuper(this, 'cancelForm', );
    }
}
