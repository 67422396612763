import { Rent } from '@/modules/rents/shared/models/rent';

export class RenterDataRequest {
    public id: number = 0;
    public name: string = '';
    public user: any = {};
    // tslint:disable-next-line:variable-name
    public user_id: number | null = null;
    // tslint:disable-next-line:variable-name
    public short_name?: string = '';
    public email: string = '';
    public phone: string = '';
    public address: string = '';
    public city: string = '';
    public country: string = '';
    public description: string = '';
    // tslint:disable-next-line:variable-name
    public renter_type_id: string = '';
    // tslint:disable-next-line:variable-name
    public related_rent_id: number = 0;
    // tslint:disable-next-line:variable-name
    public related_rent: Rent | null = null;
    public zip: string = '';
    [key: string]: any;
}
