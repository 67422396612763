import {ListFilter} from '@/shared/types';

export const rentersListFilters: ListFilter[] = [
    /*{
        name: 'is_company', label: 'Rodzaj najemcy', type: 'select', data: [
            {name: '1', label: 'Firma', value: false},
            {name: '0', label: 'Osoba fizyczna', value: false},
        ],
    },*/
];
